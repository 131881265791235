<template>
  <v-container :style="containerHeight">
    <backArrow
      v-if="detailOnly"
      :action="
        () => {
          clearAppointment();
          close();
        }
      "
    />
    <v-row v-if="!detailOnly">
      <v-col class="pb-0 py-5" cols="10">
        <p v-if="!realizeType" class="main-strong-text mb-0">
          0{{ step }} de 04
        </p>
      </v-col>
      <v-col class="pb-0" cols="2">
        <img
          @click="
            () => {
              clearAppointment();
              close();
            }
          "
          src="/img/icons/common/close-icon.svg"
          style="height: 35px"
        />
      </v-col>
    </v-row>
    <v-row
      style="overflow: hidden"
      v-if="step === steps.medical && !appointment"
    >
      <v-col cols="12" class="pt-0 mt-0 pb-1 mb-1">
        <v-autocomplete
          label="Beneficiário selecionado"
          :items="beneficiaries"
          item-value="cpf"
          item-text="name"
          v-model="userCpf"
          color="primary"
          readonly
        >
        </v-autocomplete>
      </v-col>
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Informe a especialidade e o tipo da consulta
        </p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="specialityName"
          readonly
          placeholder="Especialidade"
          @click="
            () => {
              form.comment = null;
              medicalSpeciality = true;
            }
          "
        >
          <img
            slot="prepend"
            src="/img/icons/common/search-gray.svg"
            style="width: 20px; height: 20px"
          />
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <p class="main-strong-text" style="font-size: 16px">Tipo</p>
        <v-chip
          :outlined="form.appointmentType.code !== appointmentTypes.self"
          color="primary"
          @click="() => getAppointmentType(appointmentTypes.self)"
        >
          Iniciativa própria
        </v-chip>
        <v-chip
          class="ml-2"
          :outlined="form.appointmentType.code !== appointmentTypes.refer"
          color="primary"
          @click="() => getAppointmentType(appointmentTypes.refer)"
        >
          Indicação médica
        </v-chip>
        <v-checkbox
          class="mt-16"
          v-model="form.fullfilled"
          label="Essa consulta já foi realizada"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.medical && appointment">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">Registrar Consulta</p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="form.medicalSpeciality.name"
          readonly
          placeholder="Especialidade"
          @click="() => (medicalSpeciality = true)"
        >
          <img
            slot="prepend"
            src="/img/icons/common/search-gray.svg"
            style="width: 20px; height: 20px"
          />
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <p class="main-strong-text" style="font-size: 16px">Tipo</p>
        <v-chip color="primary">
          {{ getAppointmentTypeText() }}
        </v-chip>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          class="mt-16"
          v-model="form.fullfilled"
          label="Essa consulta já foi realizada"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.doctor && !form.doctor.doctorName">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">Qual médico?</p>
      </v-col>
      <v-col cols="12">
        <v-text-field placeholder="Buscar" v-model="searchDoctor">
          <img
            slot="prepend"
            src="/img/icons/common/search-gray.svg"
            style="width: 20px; height: 20px"
            @click="() => filterDoctorHistory()"
          />
          <img
            v-if="searchDoctor"
            slot="append"
            src="/img/icons/common/close-icon.svg"
            style="width: 20px; height: 20px"
            @click="() => (searchDoctor = '')"
          />
        </v-text-field>
        <p
          v-if="doctorHistory.length > 0 && searchDoctor"
          class="main-strong-text mt-2"
          style="font-size: 16px"
        >
          Recentes
        </p>

        <v-row
          v-for="doctor in doctorHistory"
          :key="doctor.id"
          class="my-2 py-0"
          @click="
            () => {
              form.doctor = doctor;
              addDoctor = doctor;
            }
          "
        >
          <v-col class="pr-0 my-0 py-0" cols="1">
            <img
              src="/img/icons/common/clock-icon.svg"
              style="width: 20px; height: 20px; margin-top: 2px"
            />
          </v-col>
          <v-col class="my-0 py-0" cols="11">
            <p class="main-text" style="font-size: 16px">
              {{ doctor.doctorName ? doctor.doctorName : "" }}
            </p>
          </v-col>
        </v-row>

        <p
          v-if="doctors.length > 0"
          class="main-strong-text"
          style="font-size: 16px"
        >
          Lista de médicos
        </p>

        <div v-if="searchDoctor">
          <v-row
            v-for="doctor in doctors"
            :key="doctor.id"
            class="mt-4 mb-2"
            @click="
              () => {
                form.doctor = {
                  doctorName: doctor.name,
                  doctorId: doctor.id,
                  doctorPhone: doctor.phone,
                  doctorCnpjCpf: doctor.cnpj_cpf ? doctor.cnpj_cpf : '',
                  doctorCrm: doctor.xipp_council_code,
                  doctorUfCrm: doctor.xipp_council_uf,
                };
                addDoctor = form.doctor;
              }
            "
          >
            <v-col class="pr-0" cols="1">
              <img
                src="/img/icons/common/person-outline-red.svg"
                style="width: 20px; height: 20px; margin-top: 2px"
              />
            </v-col>
            <v-col cols="11">
              <p class="main-text" style="font-size: 16px">
                {{ doctor.name ? doctor.name : "" }}
              </p>
            </v-col>
          </v-row>
        </div>

        <v-row v-if="doctorNotFound()">
          <v-col cols="12">
            <p class="main-text" style="font-size: 16px">
              Médico não encontrado
            </p>
          </v-col>
          <v-col cols="12">
            <v-chip color="primary" @click="() => (newDoctor = true)">
              adicionar médico
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="step === steps.doctor && form.doctor.doctorName">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">Qual médico?</p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          readonly
          class="mb-2"
          label="Nome do médico"
          v-model="form.doctor.doctorName"
        />
        <v-row>
          <v-col cols="8">
            <v-text-field
              readonly
              class="mb-2"
              label="CRM (Apenas números)"
              v-model="form.doctor.doctorCrm"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              readonly
              class="mb-2"
              label="UF"
              v-model="form.doctor.doctorUfCrm"
            />
          </v-col>
        </v-row>
        <v-text-field
          readonly
          class="mb-2"
          v-mask="['###.###.###-##', '###.###.####-##']"
          :rules="[...cpfLen]"
          label="CPF/CNPJ"
          v-model="form.doctor.doctorCnpjCpf"
        />
        <v-text-field
          readonly
          class="mb-2"
          label="Telefone"
          v-mask="['(##) ####-####', '(##) #####-####', '+## (##) #####-####']"
          v-model="form.doctor.doctorPhone"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.date">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Informe a data, hora e o local do agendamento
        </p>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-text-field
              @click="() => (addDate = true)"
              placeholder="Data da consulta"
              readonly
              :value="formatDateInput"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              @click="() => (addHour = true)"
              placeholder="Horário"
              readonly
              v-model="form.hour"
            />
          </v-col>
          <v-col v-if="(!mapsCep && !form.place) || editAddress" cols="12">
            <google-map-autocomplete
              @input="updateAddress"
              placeholder="Digite um local"
            />
          </v-col>
          <v-col
            v-if="
              !mapsCep && form.place && form.place.length > 0 && !editAddress
            "
            cols="12"
          >
            <p style="font-size: 20px; color: #3f3f3f" class="main-strong-text">
              Local
            </p>
            <v-row class="mb-2">
              <v-col cols="10">
                <p class="generic-text" style="font-size: 16px">
                  {{ form.place }}
                </p>
              </v-col>
              <v-col
                v-if="detailOnly && !fullfilledStatus"
                @click="() => (editAddress = true)"
                cols="2"
              >
                <img
                  src="/img/icons/common/edit-pencil-pink.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pl-0 pr-0" cols="12" v-if="mapsCep">
            <p style="font-size: 20px; color: #3f3f3f" class="main-strong-text">
              Local
            </p>
            <googleMap
              :address="form.place"
              :zip-code="mapsCep"
              :get-city="getCity"
              :get-state="getState"
            />
            <p
              v-if="!appointment"
              style="font-size: 14px; color: #3f3f3f"
              class="main-text mb-1 mt-2"
            >
              {{ form.place }}{{ local.city.long_name }} -
              {{ local.state.short_name }}
            </p>
            <p
              v-if="!appointment"
              style="font-size: 14px; color: #3f3f3f"
              class="mb-0 main-text"
            >
              {{ this.local.cep }}
            </p>
            <p
              v-if="appointment"
              style="font-size: 14px; color: #3f3f3f"
              class="main-text mb-1 mt-2"
            >
              {{ form.place }}
            </p>
          </v-col>
          <v-col cols="12" class="mt-6">
            <v-row class="mt-2 mb-2">
              <v-col cols="1">
                <img
                  src="/img/icons/common/bell-icon.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
              <v-col cols="11">
                <p
                  class="main-text"
                  style="font-size: 16px"
                  @click="() => (addReminder = true)"
                >
                  {{
                    form.reminder
                      ? form.reminder.description
                      : "Adicionar lembrete"
                  }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row :class="detailOnly ? 'mt-8' : ''" v-if="step === steps.confirm">
      <v-col v-if="!realizeType" class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Confirme os dados para registrar
        </p>
      </v-col>

      <v-col v-if="realizeType" class="pt-0" cols="12">
        <p class="main-text mb-0">Especialidade</p>
        <p class="main-title" style="font-size: 32px">
          {{ specialityName }}
        </p>
        <v-chip color="#F8F8FF" style="color: var(--v-primary)">
          {{ getAppointmentTypeText() }}
        </v-chip>
        <p
          class="main-strong-text mb-0 mt-16"
          style="font-size: 20px; color: var(--v-gray2)"
        >
          Detalhes da consulta
        </p>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col v-if="!realizeType" cols="12">
            <v-text-field
              @click="() => (medicalSpeciality = true)"
              :value="specialityName"
              readonly
              placeholder="Especialidade"
            />
          </v-col>
          <v-col v-if="!realizeType" cols="12">
            <p class="main-strong-text" style="font-size: 16px">Tipo</p>
            <v-chip color="primary">
              {{ getAppointmentTypeText() }}
            </v-chip>
          </v-col>
          <v-form class="pa-0 ma-0" ref="confirmForm">
            <v-col cols="12">
              <v-autocomplete
                class="mb-2"
                label="Beneficiário selecionado"
                :items="beneficiaries"
                item-value="cpf"
                item-text="name"
                v-model="userCpf"
                color="primary"
                readonly
              >
              </v-autocomplete>
              <v-text-field
                class="mb-2"
                label="Nome do médico"
                v-model="form.doctor.doctorName"
                :rules="realizeType && required"
                @click="() => (newDoctor = true)"
              />
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    class="mb-2"
                    label="CRM (Apenas números)"
                    v-model="form.doctor.doctorCrm"
                    :rules="realizeType || form.fullfilled ? required : ''"
                    @click="() => (newDoctor = true)"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    class="mb-2"
                    label="UF"
                    readonly
                    :autofocus="realizeType || form.fullfilled"
                    @focus="$refs.confirmForm.validate()"
                    v-model="form.doctor.doctorUfCrm"
                    :rules="realizeType || form.fullfilled ? required : ''"
                    @click="() => (newDoctor = true)"
                  />
                </v-col>
              </v-row>
              <v-text-field
                class="mb-2"
                label="CPF/CNPJ"
                v-mask="['###.###.###-##', '###.###.####-##']"
                v-model="form.doctor.doctorCnpjCpf"
                @click="() => (newDoctor = true)"
                :rules="[...cpfLen]"
              />
              <v-text-field
                class="mb-2"
                label="Telefone"
                v-mask="[
                  '(##) ####-####',
                  '(##) #####-####',
                  '+## (##) #####-####',
                ]"
                :rules="[...phoneMinLen]"
                v-model="form.doctor.doctorPhone"
                @click="() => (newDoctor = true)"
              />
            </v-col>
          </v-form>
          <v-col cols="6">
            <v-text-field
              @click="() => (addDate = true)"
              label="Data da consulta"
              placeholder="Data da consulta"
              readonly
              :value="formatDateInput"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              @click="() => (addHour = true)"
              label="Horário da consulta"
              placeholder="Horário da consulta"
              readonly
              v-model="form.hour"
            />
          </v-col>
          <v-col v-if="realizeType || form.fullfilled" cols="6">
            <v-text-field
              @click="() => (addRealizationDate = true)"
              label="Data de realização"
              placeholder="Data de realização"
              readonly
              :rules="required"
              v-model="formatRealizationDateInput"
            />
          </v-col>
          <v-col v-if="realizeType || form.fullfilled" cols="6">
            <v-text-field
              @click="() => (addRealizationHour = true)"
              label="Horário de realização"
              placeholder="Horário de realização"
              readonly
              :rules="required"
              v-model="form.realizationHour"
            />
          </v-col>
          <v-col v-if="(!mapsCep && !form.place) || editAddress" cols="12">
            <google-map-autocomplete
              @input="updateAddress"
              placeholder="Digite um local"
            />
          </v-col>
          <v-col
            v-if="
              !mapsCep && form.place && form.place.length > 0 && !editAddress && !appointment.integrationCode
            "
            cols="12"
          >
            <p style="font-size: 20px; color: #3f3f3f" class="main-strong-text">
              Local
            </p>
            <v-row class="mb-2">
              <v-col cols="10">
                <p class="generic-text" style="font-size: 16px">
                  {{ form.place }}
                </p>
              </v-col>
              <v-col
                v-if="detailOnly && !fullfilledStatus"
                @click="() => (editAddress = true)"
                cols="2"
              >
                <img
                  src="/img/icons/common/edit-pencil-pink.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pl-0 pr-0" cols="12" v-if="mapsCep">
            <p
              style="font-size: 20px; color: #3f3f3f"
              class="main-strong-text"
              @click="addLocal = false"
            >
              Local
            </p>

            <googleMap
              :address="form.place"
              :zip-code="mapsCep"
              :get-city="getCity"
              :get-state="getState"
            />
            <p
              v-if="!appointment"
              style="font-size: 14px; color: #3f3f3f"
              class="main-text mb-1 mt-2"
            >
              {{ form.place }}{{ local.city.long_name }} -
              {{ local.state.short_name }}
            </p>
            <p
              v-if="!appointment"
              style="font-size: 14px; color: #3f3f3f"
              class="mb-0 main-text"
            >
              {{ this.local.cep }}
            </p>
            <p
              v-if="appointment"
              style="font-size: 14px; color: #3f3f3f"
              class="main-text mb-1 mt-2"
            >
              {{ form.place }}
            </p>
          </v-col>
          <v-col cols="12" v-if="appointment && appointment.appointmentLink">
            <v-chip
              style="font-weight: 900 !important;"
              class="text-decoration-underline"
              color="primary"
              dark
              @click="() => telemedicineLink(appointment.appointmentLink)"
            >
              Acessar telemedicina
            </v-chip>
          </v-col>
          <v-col cols="12" class="mt-6">
            <v-row class="mt-2 mb-2">
              <v-col cols="1">
                <img
                  src="/img/icons/common/bell-icon.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
              <v-col cols="11">
                <p
                  class="main-text"
                  style="font-size: 16px"
                  @click="() => (addReminder = true)"
                >
                  {{
                    form.reminder
                      ? form.reminder.description
                      : "Adicionar lembrete"
                  }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pt-0" cols="12">
            <v-row class="mb-2">
              <v-col v-if="!fullfilledStatus" cols="1">
                <img
                  src="/img/appointments/clip-icon.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
              <v-col cols="11">
                <p
                  v-if="!fullfilledStatus"
                  class="generic-text"
                  style="font-size: 16px"
                  @click="() => (addAttach = true)"
                >
                  Adicionar Anexo
                </p>
              </v-col>
              <v-col cols="12">
                <v-chip
                  class="mb-16"
                  color="primary"
                  outlined
                  @click="() => (showAttach = true)"
                >
                  Ver anexos
                </v-chip>
                <v-row class="mb-16 pb-16"></v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="overlayOn && !fullfilledStatus" class="card-overlay" />
    <v-form ref="doctorForm">
      <v-card
        elevation="16"
        style="height: 85%; top: 20%"
        v-if="newDoctor && !fullfilledStatus"
        class="medical-speciality"
      >
        <v-container class="pa-10 pt-6">
          <v-row class="mb-8">
            <v-col class="pt-0" cols="10">
              <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
                Adicionar médico
              </p>
            </v-col>
            <v-col class="pt-0" cols="2">
              <img
                @click="() => (newDoctor = false)"
                src="/img/icons/common/close-icon.svg"
                style="height: 35px"
              />
            </v-col>
          </v-row>
          <v-text-field
            class="mb-2"
            label="Nome do médico"
            v-model="addDoctor.doctorName"
            :rules="required"
          />
          <v-row>
            <v-col cols="8">
              <v-text-field
                class="mb-2"
                label="CRM (Apenas números)"
                :autofocus="realizeType || form.fullfilled"
                @focus="$refs.doctorForm.validate()"
                :rules="
                  (step === steps.confirm && form.fullfilled) || realizeType
                    ? [...required, ...numberOnly]
                    : numberOnly
                "
                v-model="addDoctor.doctorCrm"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                class="mb-2"
                label="UF"
                :items="UF"
                :rules="
                  (step === steps.confirm && form.fullfilled) || realizeType
                    ? required
                    : ''
                "
                item-text="uf"
                item-value="uf"
                v-model="addDoctor.doctorUfCrm"
              />
            </v-col>
          </v-row>
          <v-text-field
            class="mb-2"
            label="CPF/CNPJ"
            v-mask="['###.###.###-##', '###.###.####-##']"
            :rules="[...cpfLen]"
            v-model="addDoctor.doctorCnpjCpf"
          />
          <v-text-field
            class="mb-2"
            label="Telefone"
            v-mask="[
              '(##) ####-####',
              '(##) #####-####',
              '+## (##) #####-####',
              '+## (##) #####-####',
            ]"
            v-model="addDoctor.doctorPhone"
            :rules="[...phoneMinLen]"
          />

          <generic-btn
            style="width: 100%; margin-top: 10%"
            :btn-props="{ dark: true, color: 'green' }"
            :on-click="saveDoctor"
          >
            Salvar
          </generic-btn>
        </v-container>
      </v-card>
    </v-form>
    <v-card
      v-if="medicalSpeciality && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-10">
        <v-text-field
          style="background-color: white"
          placeholder="Buscar"
          v-model="search"
          @change="() => filterSpecialities()"
        >
          <img
            slot="append"
            src="/img/icons/common/search-red.svg"
            style="width: 20px; height: 20px"
            @click="() => filterSpecialities()"
          />
        </v-text-field>
        <v-text-field
          v-if="form.medicalSpeciality.name == 'Outros'"
          placeholder="Adicione um comentário"
          v-model="form.comment"
          @change="() => (medicalSpeciality = false)"
          ><img
            slot="append"
            src="/img/icons/common/plus-icon-pink.svg"
            style="width: 20px; height: 20px"
            @click="() => (medicalSpeciality = false)"
          />
        </v-text-field>
        <v-card
          elevation="0"
          style="overflow: auto; background-color: white; height: 59vh"
        >
          <p
            v-for="speciality in specialities"
            :key="speciality.id"
            class="main-text mt-4 mb-8"
            style="font-size: 16px"
            @click="
              speciality.name != 'Outros'
                ? closeSpeciality(speciality)
                : (form.medicalSpeciality = speciality)
            "
          >
            {{ speciality && speciality.name }}
          </p>
        </v-card>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      v-if="addDate && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Data da consulta
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addDate = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-date-picker
          flat
          full-width
          v-model="form.date"
          color="#706CE0"
          no-title
        />

        <generic-btn
          style="width: 100%; margin-top: -5%"
          :btn-props="{}"
          :on-click="() => (addDate = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      style="height: 90%; top: 15%"
      v-if="addHour && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Horário
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addHour = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-row class="mb-10">
          <v-row class="d-flex justify-center text-h1 primary--text"
            >{{ timePicker.hour }}:{{ timePicker.minutes }}</v-row
          >
          <v-row class="d-flex justify-center mt-10">
            <v-col cols="5">
              <v-select
                v-model="timePicker.hour"
                :items="hourOptions"
                item-text="text"
                item-value="value"
                label="Hora(s)"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="timePicker.minutes"
                :items="minutesOptions"
                item-text="text"
                item-value="value"
                label="Minuto(s)"
              ></v-select>
            </v-col>
          </v-row>
        </v-row>

        <generic-btn
          style="width: 100%; margin-top: 0%"
          :btn-props="{}"
          :on-click="() => (addHour = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card elevation="16" v-if="addRealizationDate" class="medical-speciality">
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Data de realização
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addRealizationDate = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-date-picker
          flat
          full-width
          v-model="form.realizationDate"
          color="#706CE0"
          no-title
          :max="moment().format('YYYY-MM-DD')"
        />

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="() => (addRealizationDate = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      style="height: 90%; top: 15%"
      v-if="addRealizationHour && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Horário de realização
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addRealizationHour = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-row class="mb-10">
          <v-row class="d-flex justify-center text-h1 primary--text"
            >{{ realizationTimePicker.hour }}:{{
              realizationTimePicker.minutes
            }}</v-row
          >
          <v-row class="d-flex justify-center mt-10">
            <v-col cols="5">
              <v-select
                v-model="realizationTimePicker.hour"
                :items="hourOptions"
                item-text="text"
                item-value="value"
                label="Hora(s)"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="realizationTimePicker.minutes"
                :items="minutesOptions"
                item-text="text"
                item-value="value"
                label="Minuto(s)"
              ></v-select>
            </v-col>
          </v-row>
        </v-row>

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="() => (addRealizationHour = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      :style="{
        height: form.attachments.length > 0 ? '90%' : '25%',
        top: form.attachments.length > 0 ? '15%' : '80%',
      }"
      v-if="showAttach"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Anexos
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (showAttach = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-list v-if="form.attachments.length > 0">
          <v-list-item
            v-for="attach in form.attachments"
            :key="attach.fileName"
            two-line
          >
            <v-list-item-content>
              <v-list-item-title style="font-size: 20px">{{
                attach.attachmentType ? attach.attachmentType.name : "Arquivo"
              }}</v-list-item-title>
              <v-list-item-subtitle style="font-size: 20px">
                <a
                  style="color: gray"
                  :href="`data:application/octet-stream;base64,${
                    attach.fileContent ? attach.fileContent : ''
                  }`"
                  :download="getAttachmentDownload(attach)"
                  @click="onClickNativeApp(attach)"
                >
                  {{ attach.fileName ? attach.fileName : "" }}
                </a>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <img
                @click="deleteAttach(attach)"
                src="/img/icons/common/close-icon.svg"
                style="height: 30px"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <p v-else class="generic-text text-center">Sem anexos adicionados</p>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      style="height: 85%; top: 20%"
      v-if="addReminder && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Alarme
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addReminder = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <div style="height: 350px" class="scroll-hidden">
          <v-row
            :style="
              form.reminder && form.reminder.id === alert.id
                ? { backgroundColor: '#F8F8FF' }
                : {}
            "
            v-for="alert in reminders"
            :key="alert.id"
            class="pt-1"
            @click="() => (form.reminder = alert)"
          >
            <v-col class="pb-0" cols="10">
              <p class="main-text" style="font-size: 16px">
                {{ alert.description }}
              </p>
            </v-col>

            <v-col
              v-if="form.reminder && form.reminder.id === alert.id"
              class="pb-0 pt-3"
              cols="2"
            >
              <img
                src="/img/appointments/check-icon.svg"
                style="width: 20px; height: 20px; margin-top: 2px"
              />
            </v-col>
          </v-row>
        </div>

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="() => (addReminder = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>

    <label v-show="false" id="fileUpload" ref="fileUpload" for="file">
      <input type="file" ref="file" @change="updateFiles" v-show="false" />
    </label>

    <v-card
      elevation="16"
      style="height: 85%; top: 20%"
      v-if="addAttach && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Anexos
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addAttach = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <div style="height: 350px" class="scroll-hidden">
          <v-row
            :style="
              currentAttachType === type ? { backgroundColor: '#F8F8FF' } : {}
            "
            v-for="type in attachTypes"
            :key="type.id"
            class="pt-1"
            @click="() => (currentAttachType = type)"
          >
            <v-col class="pb-0" cols="10">
              <p class="main-text" style="font-size: 16px">
                {{ type.name }}
              </p>
            </v-col>

            <v-col v-if="currentAttachType === type" class="pb-0 pt-3" cols="2">
              <img
                src="/img/appointments/check-icon.svg"
                style="width: 20px; height: 20px; margin-top: 2px"
              />
            </v-col>
          </v-row>
        </div>

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="triggerClick"
        >
          Adicionar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      v-if="addLocal && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-10 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Local
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addLocal = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-text-field label="CEP" v-model="local.cep" v-mask="'#####-###'" :rules="required"/>

        <v-text-field label="Bairro" v-model="local.neighbour" :rules="required" />

        <v-text-field label="Rua" v-model="local.address" :rules="required"/>

        <v-text-field
          label="Nº"
          :rules="required && local.number && numberOnly"
          v-model="local.number"
        />

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{ disabled: disableLocalForm }"
          :on-click="saveAddress"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-layout v-if="!fullfilledStatus" justify-center align-end>
      <v-card style="height: 15%" elevation="16" class="step-btn">
        <generic-btn
          style="width: 90%; margin-top: 8%"
          class="ml-5"
          :btn-props="{
            disabled:
              disableSave ||
              !this.form.realizationDate ||
              !this.form.realizationHour,
          }"
          :on-click="() => realizeAppointment()"
          v-if="step === steps.confirm && realizeType && !detailOnly"
        >
          Comparecer
        </generic-btn>

        <generic-btn
          style="width: 90%; margin-top: 8%"
          class="ml-5"
          :btn-props="{ disabled: disableSave }"
          :on-click="() => updateAppointment()"
          v-if="step === steps.confirm && detailOnly"
        >
          Alterar
        </generic-btn>
        <generic-btn
          style="width: 90%; margin-top: 7%"
          class="ml-5"
          :btn-props="{
            disabled:
              (!this.form.medicalSpeciality.name && !this.form.comment) ||
              !this.form.appointmentType.code,
          }"
          :on-click="() => step++"
          v-if="step === steps.medical && !realizeType"
        >
          Próximo
        </generic-btn>
        <generic-btn
          style="margin-top: 7%; margin-left: 8%; width: 40%"
          :btn-props="{ outlined: true }"
          :on-click="() => step--"
          v-if="step !== steps.medical && !realizeType"
        >
          Voltar
        </generic-btn>
        <generic-btn
          style="margin-top: 7%; width: 40%"
          class="ml-5"
          :btn-props="{ disabled: disableNext }"
          :on-click="() => step++"
          v-if="
            step !== steps.medical && step !== steps.confirm && !realizeType
          "
        >
          Próximo
        </generic-btn>
        <generic-btn
          style="margin-top: 7%; width: 40%"
          class="ml-5"
          :btn-props="{ disabled: disableSave }"
          :on-click="() => saveAppointment()"
          v-if="step === steps.confirm && !realizeType"
        >
          Registrar
        </generic-btn>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import {
  appointmentTypes,
  regexValidations,
  appointmentsStatus,
} from "../../common/constants";
import { getAllSpecialities } from "../../services/healthcare/MedicalSpeciality";
import MedicalAppointmentEventService from "../../services/healthcare/MedicalAppointmentEvent";
import { getReminders } from "../../services/healthcare/Reminder";
import { getAppointmentTypes } from "../../services/healthcare/AppointmentType";
import { getAppointmentAttachmentTypes } from "../../services/healthcare/AttachmentType";
import AccountService from "../../services/contract/AccountService";
import { mapMutations } from "vuex";
import moment from "moment-timezone";
import googleMap from "../../components/google-maps/google-map";
import backArrow from "../../components/covid/back-arrow";
import GoogleMapAutocomplete from "../../components/google-maps/google-map-autocomplete";
import CountryService from "../../services/security/CountryService";
import Vue from "vue";
import {Capacitor} from "@capacitor/core";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {Share} from "@capacitor/share";

export default {
  name: "AppointmentRegister",
  components: {
    googleMap,
    backArrow,
    GoogleMapAutocomplete,
  },
  props: {
    appointment: {
      type: Object,
      required: false,
    },
    clearAppointment: {
      type: Function,
      required: true,
    },
    close: {
      required: true,
      type: Function,
    },
    success: {
      required: false,
      type: Function,
    },
    realizeType: {
      type: Boolean,
      default: false,
    },
    detailOnly: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      required: false,
    },
    userCpf: {
      type: String,
      required: false,
    },
    dependentId: {
      type: Number,
      required: false,
    },
    beneficiaries: {
      type: Array,
    },
  },
  data() {
    return {
      moment,
      appointmentTypes,
      appointmentsStatus,
      currentAttachType: null,
      editAddress: false,
      newDoctor: false,
      addRealizationDate: false,
      addRealizationHour: false,
      addDate: false,
      addHour: false,
      addLocal: false,
      addReminder: false,
      addAttach: false,
      showAttach: false,
      step: 1,
      steps: {
        medical: 1,
        doctor: 2,
        date: 3,
        confirm: 4,
      },
      timerId: undefined,
      medicalSpeciality: false,
      addDoctor: {
        doctorName: null,
        doctorPhone: null,
        doctorCrm: null,
        doctorId: null,
        doctorCnpjCpf: null,
        doctorUfCrm: null,
      },
      local: {
        cep: null,
        neighbour: "",
        address: "",
        number: "",
        city: "",
        state: "",
      },
      mapsCep: null,
      form: {
        fullfilled: false,
        appointmentType: { code: null },
        medicalSpeciality: { name: null },
        doctor: { doctorName: null },
        date: null,
        realizationDate: null,
        realizationHour: null,
        hour: null,
        place: null,
        reminder: null,
        attachments: [],
        userId: null,
        dependentId: null,
        comment: null,
      },
      timePicker: {
        hour: "00",
        minutes: "00",
      },
      realizationTimePicker: {
        hour: "00",
        minutes: "00",
      },
      hourOptions: [
        { text: "00", value: "00" },
        { text: "01", value: "01" },
        { text: "02", value: "02" },
        { text: "03", value: "03" },
        { text: "04", value: "04" },
        { text: "05", value: "05" },
        { text: "06", value: "06" },
        { text: "07", value: "07" },
        { text: "08", value: "08" },
        { text: "09", value: "09" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
        { text: "13", value: "13" },
        { text: "14", value: "14" },
        { text: "15", value: "15" },
        { text: "16", value: "16" },
        { text: "17", value: "17" },
        { text: "18", value: "18" },
        { text: "19", value: "19" },
        { text: "20", value: "20" },
        { text: "21", value: "21" },
        { text: "22", value: "22" },
        { text: "23", value: "23" },
      ],
      minutesOptions: [
        { text: "00", value: "00" },
        { text: "05", value: "05" },
        { text: "10", value: "10" },
        { text: "15", value: "15" },
        { text: "20", value: "20" },
        { text: "25", value: "25" },
        { text: "30", value: "30" },
        { text: "35", value: "35" },
        { text: "40", value: "40" },
        { text: "45", value: "45" },
        { text: "50", value: "50" },
        { text: "55", value: "55" },
      ],
      allSpecialities: [],
      specialities: [],
      allDoctorHistory: [],
      doctorHistory: [],
      allDoctors: [],
      doctors: [],
      reminders: [],
      types: [],
      attachTypes: [],
      search: "",
      searchDoctor: "",
      doctorValidation: true,
      UF: [],
      numberOnly: [
        (value) => {
          const pattern = /^\d*$/;
          return value && (pattern.test(value) || "Precisa ser um número");
        },
      ],
      required: [(value) => !!value || "Campo obrigatório"],
      phoneMinLen: [
        (value) =>
          !value ||
          (value.length >= 14 || value.length <= 1
            ? true
            : "Mínimo 8 números + DDD"),
      ],
      cpfLen: [
        (value) =>
          !value ||
          value.length === 14 ||
          value.length === 15 ||
          "Precisa ter entre 11 e 12 números",
      ],
    };
  },
  watch: {
    "form.fullfilled"(newValue) {
      if (newValue) {
        this.form.realizationDate = moment().format("YYYY-MM-DD");
        this.form.realizationHour = moment().format("HH:mm");
      } else {
        this.form.realizationDate = null;
        this.form.realizationHour = null;
      }
    },
    search() {
      this.filterSpecialities();
    },
    searchDoctor(value) {
      this.doctors = [];
      this.doctorHistory = [];
      this.allDoctors = [];
      this.addDoctor.doctorName = value;
      this.fetchEntriesDebounce();
    },
    addHour(val) {
      this.form.hour = val
        ? ""
        : `${this.timePicker.hour}:${this.timePicker.minutes}`;
    },
    addRealizationHour(val) {
      this.form.realizationHour = val
        ? ""
        : `${this.realizationTimePicker.hour}:${this.realizationTimePicker.minutes}`;
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    updateAppointmentTotalizers() {
      this.$emit("updateAppointmentTotalizers");
    },
    getAllCountry() {
      this._countryService.getAllCountry().then((response) => {
        if (response.data) {
          this.UF = response.data;
        }
      });
    },
    verifyIsRealized() {
      this.$emit("verifyIsRealized");
    },
    updateAddress(data) {
      const { code, neighborhood, region, city, log, number } = data;

      this.local.cep = code;
      this.local.neighbour = neighborhood;
      this.local.address = log;
      this.local.city = city;
      this.local.state = region;
      this.local.number = number;

      // Enable Form to finish address register
      this.addLocal = true;
    },
    saveDoctor() {
      let valid = this.$refs.doctorForm.validate();

      if (valid) {
        Vue.set(this.form, "doctor", this.addDoctor);
        this.newDoctor = false;
      }
    },
    readFileContent(file) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new DOMException("Unable to parse the file."));
        };

        fileReader.onload = () =>
          resolve({
            name: file.name,
            body: fileReader.result,
            size: file.size,
          });

        fileReader.readAsDataURL(file);
      });
    },
    async updateFiles(files) {
      const file = await Promise.resolve(
        this.readFileContent(files.target.files[0])
      );

      this.form.attachments.push({
        fileName: file.name.substring(0, file.name.lastIndexOf(".")) || "",
        contentType:
          file.name.substring(file.name.lastIndexOf("."), file.length) || "",
        fileContent: file.body.split(",")[1],
        attachmentType: this.currentAttachType,
      });
    },
    triggerClick() {
      this.$refs.file.click();
      this.addAttach = false;
    },
    deleteAttach(attach) {
      let remove = this.form.attachments.findIndex((file) => attach === file);
      this.form.attachments.splice(remove, 1);
    },
    getAppointmentType(type) {
      this.types.filter((t) => {
        if (t.code === type) this.form.appointmentType = t;
      });
    },
    getAppointmentTypeText() {
      let type = this.form.appointmentType.code;

      if (type === appointmentTypes.self) return "Iniciativa própria";

      if (type === appointmentTypes.suggestion) return "Protocolo Eu Protegido";

      if (type === appointmentTypes.refer) return "Indicação médica";
    },
    doctorNotFound() {
      return (
        this.doctors.length === 0 &&
        this.doctorHistory.length === 0 &&
        this.searchDoctor
      );
    },
    closeSpeciality(speciality) {
      this.form.medicalSpeciality = speciality;
      this.medicalSpeciality = false;
    },
    getCity(city) {
      this.local.city = city;
    },
    getState(state) {
      this.local.state = state;
    },
    saveAddress() {
      let { address, number, cep, neighbour } = this.local;

      if (cep) {
        this.mapsCep = cep;

        if (neighbour) this.form.place = `${address}, ${number}, ${neighbour}/`;
        else this.form.place = `${address}, ${number}/`;
      } else {
        if (neighbour) this.form.place = `${address}, ${number}, ${neighbour}`;
        else this.form.place = `${address}, ${number}`;
      }

      this.addLocal = false;
      this.editAddress = false;
    },
    async getSpecialities() {
      await getAllSpecialities()
        .then((res) => {
          this.specialities = res.data;
          this.allSpecialities = this.specialities;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar especialidades médicas!",
            type: "error",
          })
        );
    },
    getReminders() {
      this.loading(true);

      getReminders()
        .then((res) => {
          this.reminders = res.data;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar alertas!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    getTypes() {
      this.loading(true);

      getAppointmentTypes()
        .then((res) => {
          this.types = res.data;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar tipos de consulta!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    getAttachTypes() {
      this.loading(true);

      getAppointmentAttachmentTypes()
        .then((res) => {
          this.attachTypes = res.data;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar tipos de anexos!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    async getDoctorHistory() {
      await this._appointmentEventService
        .getDoctorHistory(this.userId, this.dependentId)
        .then((res) => {
          this.doctorHistory = res.data;
          this.allDoctorHistory = this.doctorHistory;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar histórico dos médicos!",
            type: "error",
          })
        );
    },
    getDoctors() {
      this.loading(true);
      this._accountService
        .getDoctors(this.searchDoctor)
        .then((res) => {
          this.doctors = [];
          res.data.forEach((doctor) => {
            this.doctors.push({
              ...doctor,
              doctorUfCrm: doctor.xipp_council_code && doctor.xipp_council_code,
            });
          });
          this.allDoctors = this.doctors;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar os doutores!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    filterSpecialities() {
      if (this.search) {
        let filtered = this.allSpecialities.filter((article) => {
          let query = this.search.toLowerCase();
          let name = article.name && article.name.toLowerCase();

          return name && name.indexOf(query) > -1;
        });

        this.specialities = filtered;
      } else {
        this.specialities = this.allSpecialities;
      }
    },
    fetchEntriesDebounce() {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.filterDoctorHistory();
      }, 2000);
    },
    filterDoctorHistory() {
      if (this.searchDoctor) {
        let filtered = this.allDoctorHistory.filter((article) => {
          let query = this.searchDoctor.toLowerCase();
          let name = article.doctorName && article.doctorName.toLowerCase();

          return name && name.indexOf(query) > -1;
        });

        this.doctorHistory = filtered;
        this.getDoctors();
      } else {
        this.doctorHistory = this.allDoctorHistory;
      }
    },
    updateAppointment() {
      this.loading(true);
      this.form.hour = `${this.timePicker.hour}:${this.timePicker.minutes}`;
      this.form.realizationHour = `${this.realizationTimePicker.hour}:${this.realizationTimePicker.minutes}`;

      let date = moment(`${this.form.date} ${this.form.hour}`).format(
        "YYYY-MM-DD[T]HH:mm:ss"
      );
      let address = `${this.form.place}`;
      let placeCep =
        this.appointment &&
        this.appointment.place &&
        this.appointment.place.match(regexValidations.cep);
      let parseRealizationDate = null;

      if (this.form.realizationDate && this.form.realizationHour) {
        parseRealizationDate = moment(
          `${this.form.realizationDate} ${this.form.realizationHour}`
        ).format("YYYY-MM-DD[T]HH:mm:SS[.000Z]");
      }

      if (placeCep)
        if (this.local.city && this.local.state && this.local.cep)
          address = `${this.form.place}${this.local.city.long_name} - ${this.local.state.short_name} / ${this.local.cep}`;

      let holderReminder = false
      let userId = this.form.userId
      if(userId == null){
        userId = this.beneficiaries.find(beneficiary => beneficiary.userId != null && beneficiary.dependentId == null).userId
        holderReminder = true
      }

      let data = {
        ...this.form,
        ...this.form.doctor,
        userId: userId,
        scheduledDate: date,
        holderReminder: holderReminder,
        realizationDate: this.form.fullfilled ? parseRealizationDate : null,
        realizationUntilDate:
          this.appointment && this.appointment.realizationUntilDate
            ? this.appointment.realizationUntilDate
            : null,
        place: address,
      };

      this._appointmentEventService
        .updateAppointment(this.appointment.id, data)
        .then(() => {
          this.loading(false);
          this.close();
          this.success();
          this.updateAppointmentTotalizers();
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao salvar a consulta!",
            type: "error",
          })
        )
        .finally(() => {
          this.loading(false);
          this.clearAppointment();
        });
    },
    saveAppointment() {
      this.loading(true);
      this.form.hour = `${this.timePicker.hour}:${this.timePicker.minutes}`;
      this.form.realizationHour = `${this.realizationTimePicker.hour}:${this.realizationTimePicker.minutes}`;

      let date = moment(`${this.form.date} ${this.form.hour}`).format(
        "YYYY-MM-DD[T]HH:mm:SS[.000Z]"
      );
      let address = `${this.form.place}`;
      let placeCep =
        this.appointment &&
        this.appointment.place &&
        this.appointment.place.match(regexValidations.cep);
      let parseRealizationDate = null;

      if (this.form.realizationDate && this.form.realizationHour) {
        parseRealizationDate = moment(
          `${this.form.realizationDate} ${this.form.realizationHour}`
        ).format("YYYY-MM-DD[T]HH:mm:SS[.000Z]");
      }

      if (!placeCep)
        if (this.local.city && this.local.state && this.local.cep)
          address = `${this.form.place}${this.local.city.long_name} - ${this.local.state.short_name} / ${this.local.cep}`;

      let holderReminder = false
      let userId = this.form.userId
      if(userId == null){
        userId = this.beneficiaries.find(beneficiary => beneficiary.userId != null && beneficiary.dependentId == null).userId
        holderReminder = true
      }

      let data = {
        ...this.form,
        ...this.form.doctor,
        userId: userId,
        scheduledDate: date,
        holderReminder: holderReminder,
        realizationDate: this.form.fullfilled ? parseRealizationDate : null,
        realizationUntilDate:
          this.appointment && this.appointment.realizationUntilDate
            ? this.appointment.realizationUntilDate
            : null,
        place: address,
      };

      this._appointmentEventService
        .createAppointment(data)
        .then(() => {
          this.close();
          this.success();
          this.updateAppointmentTotalizers();
          if (this.form.fullfilled === true) {
            this.verifyIsRealized();
          }
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao salvar a consulta!",
            type: "error",
          })
        )
        .finally(() => {
          this.loading(false);
          this.clearAppointment();
        });
    },
    realizeAppointment() {
      if (this.doctorValid) {
        this.loading(true);
        this.form.hour = `${this.timePicker.hour}:${this.timePicker.minutes}`;
        this.form.realizationHour = `${this.realizationTimePicker.hour}:${this.realizationTimePicker.minutes}`;

        let parseRealizationDate = moment(
          `${this.form.realizationDate} ${this.form.realizationHour}`
        ).format("YYYY-MM-DD[T]HH:mm:ss");
        let address = `${this.form.place}`;
        let placeCep =
          this.appointment &&
          this.appointment.place &&
          this.appointment.place.match(regexValidations.cep);

        if (placeCep)
          if (this.local.city && this.local.state && this.local.cep)
            address = `${this.form.place}${this.local.city.long_name} - ${this.local.state.short_name} / ${this.local.cep}`;

        let data = {
          ...this.form.doctor,
          id: this.form.id,
          userId: this.form.userId,
          dependentId: this.form.dependentId,
          realizationDate: parseRealizationDate,
          medicalSpeciality: this.form.medicalSpeciality,
          place: address,
          scheduledDate: this.appointment.scheduledDate,
          realizationUntilDate:
            this.appointment && this.appointment.realizationUntilDate
              ? this.appointment.realizationUntilDate
              : null,
          appointmentType: { id: this.appointment.appointmentType.id },
          comment: this.appointment.comment,
          attachments: this.form.attachments,
        };

        this._appointmentEventService
          .realizeAppointment(data)
          .then(() => {
            this.loading(false);
            this.close();
            this.updateAppointmentTotalizers();
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao registrar consulta! Por favor preencha todos os campos.",
              type: "error",
            });
          })
          .finally(() => {
            this.loading(false);
            this.clearAppointment();
          });
      } else {
        this.newDoctor = true;
        this.$refs.doctorForm.validate();
      }
    },
    getAttachmentType(attach) {
      return attach.contentType ? attach.contentType.replaceAll(".", "") : "";
    },
    getAttachmentDownload(attach) {
      return attach.fileName && attach.contentType
        ? attach.fileName + attach.contentType
        : "";
    },
    async onClickNativeApp(attach) {
      if(Capacitor.isNativePlatform()) {
        this.loading(true);
        const file = attach.fileName && attach.contentType ? attach.fileName + attach.contentType : null;
        if(file) {
          await Filesystem.writeFile({
            path: file,
            data: 'data:application/octet-stream;base64,' + attach.fileContent,
            directory: Directory.Documents,
          }).then((result) => {

            Share.share({
              title: 'Consulta',
              text: 'Anexo de Consulta',
              url: result.uri,
              dialogTitle: 'Eu Protegido',
            });

            setTimeout(() => {
              this.loading(false);
            }, 1500)

          }).catch(() => {
            this.loading(false);
          });
        }
      }
    },
    telemedicineLink(link) {
      window.open(link, '_blank');
    },
  },
  beforeCreate() {
    this._appointmentEventService = new MedicalAppointmentEventService();
    this._accountService = new AccountService();
    this._countryService = new CountryService();
  },
  computed: {
    specialityName() {
      if (this.form.medicalSpeciality.name === "Outros") {
        return `${this.form.medicalSpeciality.name}  ${
          this.form.comment == null ? "" : "- " + this.form.comment
        }`;
      } else {
        return this.form.medicalSpeciality.name;
      }
    },
    fullfilledStatus() {
      if (this.appointment) {
        if (this.appointment.status === appointmentsStatus.fullfilled)
          return true;
        else return false;
      } else return false;
    },
    overlayOn() {
      return (
        this.newDoctor ||
        this.addLocal ||
        this.addDate ||
        this.addHour ||
        this.addReminder ||
        this.addAttach ||
        this.showAttach ||
        this.medicalSpeciality
      );
    },
    containerHeight() {
      if (this.step === this.steps.date) return { height: "90vh" };

      if (this.step === this.steps.confirm) return { height: "100vh" };
      else return { height: "90vh" };
    },
    disableNext() {
      if (this.step === this.steps.doctor) return !this.form.doctor.doctorName;

      if (this.step === this.steps.date)
        return !this.form.date || !this.form.hour || !this.form.place;
      else return false;
    },
    disableLocalForm() {
      return !this.local.address || !this.local.number || !this.local.cep || !this.local.neighbour;
    },
    formatDateInput() {
      return this.form.date ? moment(this.form.date).format("DD/MM/YYYY") : "";
    },
    formatRealizationDateInput() {
      return this.form.realizationDate
        ? moment(this.form.realizationDate).format("DD/MM/YYYY")
        : "";
    },
    doctorValid() {
      return (
        this.form.doctor.doctorId ||
        !!(this.addDoctor.doctorName && this.addDoctor.doctorCrm)
      );
    },
    disableSave() {
      return (
        !this.form.medicalSpeciality ||
        !this.form.doctor.doctorName ||
        !this.form.date ||
        !this.form.hour ||
        !this.form.place ||
        (this.form.fullfilled && !this.form.doctor.doctorCrm) ||
        !this.form.doctor.doctorUfCrm
      );
    },
  },
  async mounted() {
    if (window) {
      window.scrollTo(0, 0);
    }

    this.loading(true);
    await this.getSpecialities();
    await this.getDoctorHistory();
    await this.getAllCountry();

    this.getReminders();
    this.getTypes();
    this.getAttachTypes();
    this.loading(false);

    this.form.userId = this.userId;
    this.form.dependentId = this.dependentId;

    if (this.realizeType) {
      this.step = this.steps.confirm;
    }

    if (this.appointment) {
      let cep =
        this.appointment.place &&
        this.appointment.place.match(regexValidations.cep);

      if (cep && cep[0]) {
        this.mapsCep = cep[0];
        this.local.cep = cep[0];
      }

      this.addDoctor = {
        doctorName: this.appointment.doctorName,
        doctorCrm: this.appointment.doctorCrm,
        doctorId: this.appointment.doctorId,
        doctorPhone: this.appointment.doctorPhone,
        doctorCnpjCpf: this.appointment.doctorCnpjCpf,
        doctorUfCrm: this.appointment.doctorUfCrm,
      };

      let actualDate = null;
      let actualHour = null;
      if (!this.detailOnly) {
        actualDate = this.appointment.realizationDate
          ? moment(this.appointment.realizationDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        actualHour = this.appointment.realizationDate
          ? moment(this.appointment.realizationDate).format("HH:mm")
          : moment().format("HH:mm");
      } else {
        actualDate = this.appointment.realizationDate
          ? moment(this.appointment.realizationDate).format("YYYY-MM-DD")
          : null;
        actualHour = this.appointment.realizationDate
          ? moment(this.appointment.realizationDate).format("HH:mm")
          : null;
      }

      this.form = {
        ...this.appointment,
        realizationDate: actualDate,
        realizationHour: actualHour,
        fullfilled: false,
        doctor: {
          doctorName: this.appointment.doctorName,
          doctorCrm: this.appointment.doctorCrm,
          doctorId: this.appointment.doctorId,
          doctorPhone: this.appointment.doctorPhone,
          doctorCnpjCpf: this.appointment.doctorCnpjCpf,
          doctorUfCrm: this.appointment.doctorUfCrm,
        },
        date: this.appointment.scheduledDate
          ? moment(this.appointment.scheduledDate).format("YYYY-MM-DD")
          : null,
        hour: this.appointment.scheduledDate
          ? moment(this.appointment.scheduledDate).format("HH:mm")
          : null,
        appointmentType: this.appointment.appointmentType
          ? this.appointment.appointmentType
          : { code: null },
        medicalSpeciality: this.appointment.medicalSpeciality
          ? this.appointment.medicalSpeciality
          : { name: null },
      };
    }
  },
};
</script>

<style>
.step-btn {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 110px;
  position: fixed;
  left: 0;
  z-index: 10;
  bottom: 0 !important;
}

.medical-speciality {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 75%;
  position: fixed;
  top: 30%;
  left: 0;
  bottom: 0;
  z-index: 20;
  -webkit-animation: medical-speciality 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: medical-speciality 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.card-overlay {
  width: 100vw;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  background: rgba(0, 0, 0, 0.6);
}

.scroll-hidden {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

@-webkit-keyframes medical-speciality {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes medical-speciality {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
</style>
