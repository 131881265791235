/* eslint-disable */
import HttpService from '../HttpService';
export default class CountryService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`security`);
  }

  getAllCountry() {
    return this._httpService.get('/country/');
  }

}
