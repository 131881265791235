import HttpService from '../HttpService'

export default class MedicalAppointmentEventService {
    constructor() {
        this._httpService = new HttpService(`healthcare/medical_appointment_event`);
    }

    async getTotalizers(userId, dependentId){
        return await this._httpService.get('/get_medical_appointment_event_totalizers', { userId, dependentId });
    }

    async getAppointmentsFindAllByUser(){
        return await this._httpService.get('/find_all_by_user');
    }

    async findAllByUserAndFilter(params, userId, dependentId){
        params.userId = userId;
        params.dependentId = dependentId;
        return await this._httpService.get('/find_all_by_user_and_filter', params);
    }

    async getDoctorHistory(userId, dependentId){
        return await this._httpService.get('/find_doctor_history', { userId, dependentId });
    }

    async createAppointment(data){
        return await this._httpService.post('/', data);
    }

    async updateAppointment(id, data){
        return await this._httpService.put(`/${id}`, data);
    }

    async realizeAppointment(data){
        return await this._httpService.post('/realize_appointment', data);
    }

    async findById(id){
        return await this._httpService.get(`/${id}`);
    }

    async createTelemedicineAppointment(data, holderCpf){
        return await this._httpService.post(`/telemedicine-appointment?holderCpf=${holderCpf}`, data);
    }

    async createURLTelemedicineDuty(holderCpf, cpf, userId, dependentId){
        const params = new URLSearchParams();
        params.append('holderCpf', holderCpf);
        params.append('cpf', cpf);
        if(userId) {
            params.append('userId', userId);
        }
        if(dependentId) {
            params.append('dependentId', dependentId);
        }
        return await this._httpService.post(`/telemedicine/duty-appointment?${params}`);
    }

    async getURLFromTelemedicineScheduleTerms() {
        return await this._httpService.get('/telemedicine/schedule-terms-url');
    }

    async getURLFromTelemedicineDutyTerms() {
        return await this._httpService.get('/telemedicine/duty-terms-url');
    }

    async getURLFromTelemedicineDependentTerms() {
        return await this._httpService.get('/telemedicine/dependent-terms-url');
    }
}
