<template>
  <v-container
    :style="{
      height: '100vh',
      display: 'block',
      width: '100vw',
      background: !register ? 'rgba(255, 255, 255, .5)' : 'white',
    }"
    class="pa-6 pt-2 fluid bg-effect"
  >
    <backArrow v-if="!register" :route="'root'" />
    <v-layout
      class="mt-1"
      v-if="!register && !registerSuccess"
      align-end
      justify-end
    >
      <img
        @click="$router.push('/principais-duvidas')"
        src="/img/nick/question-nick.svg"
        style="width: 55px"
      />
    </v-layout>
    <v-layout v-if="!register && !registerSuccess" align-start justify-start>
      <v-row>
        <v-col cols="6" class="pb-0">
          <!--         <v-select-->
          <!--           :items="['aa', 'ss']"-->
          <!--           rounded-->
          <!--           background-color="#e4e7f8"-->
          <!--           append-icon="fas fa-chevron-down"-->
          <!--           height="35"-->
          <!--         />-->
        </v-col>
      </v-row>
    </v-layout>
    <Totalizer
      v-if="!register && !registerSuccess"
      class="mb-2 pt-10"
      :totals="totals"
      title="Consultas"
      title-icon="/img/appointments/stethoscope-image.svg"
      color="#706CE0"
      :height="80"
      :tile-icon-right="40"
      :tile-icon-top="80"
      :tile-icon-width="130"
      :tile-icon-height="110"
    />
    <v-row class="pt-0 mt-0 pb-1 mb-1 px-4">
      <v-col cols="12" class="pt-0 mt-0 pb-1 mb-1">
        <v-autocomplete
          v-if="showComboBeneficiaries"
          label="Selecione o beneficiário"
          :items="beneficiaries"
          v-model="user"
          return-object
          item-value="cpf"
          item-text="name"
          color="primary"
          :disabled="busy"
          @change="onClickComboBeneficiary()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-layout v-if="!register && !registerSuccess" justify-center align-center>
      <v-card
        style="background: rgba(231, 234, 254, 0.6)"
        class="option-list-card list-card mb-6"
      >
        <v-row justify="center">
          <v-col class="pl-6 pr-0 pt-5" cols="7">
            <div
              :class="!activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
              :style="!activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="text-center filter-text generic-text mt-1 mb-0"
                @click="onClickTab()"
              >
                Próximas e Atrasadas
              </p>
            </div>
          </v-col>
          <v-col class="pl-0 pr-7 pt-5" cols="5">
            <div
              :class="activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
              :style="activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="
                  text-center
                  history-margin-adjust
                  filter-text
                  generic-text
                  mb-0
                "
                @click="onClickTab()"
              >
                Histórico
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-layout>
    <v-layout v-if="!register && !registerSuccess" align-center justify-center>
      <ProgressiveLoader
        class="container"
        :disabled="busy"
        @reachedEnd="getAppointments"
      >
        <v-row v-if="!activeFilter" align="center" justify="center">
          <v-col v-if="lateAppointments.length > 0" cols="12">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">
              Atrasadas
            </p>
          </v-col>
          <v-col
            v-for="appointment in lateAppointments"
            :key="appointment.id"
            cols="12"
          >
            <AppointmentCard
              v-if="isWaiting(appointment)"
              color="tertiary"
              :appointment="appointment"
              :isTelemedicine="appointment.integrationCode ? true : false"
              :action="
                () => {
                  setStatusToShowCombo(false);
                  currentAppointment = appointment;
                  realizeAppointment = false;
                  register = true;
                }
              "
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailAppointment = true;
                  realizeAppointment = true;
                }
              "
            />
            <AppointmentCard
              v-else-if="isScheduled(appointment)"
              color="green"
              :appointment="appointment"
              btn-title="Comparecimento"
              scheduled-type
              :isTelemedicine="appointment.integrationCode ? true : false"
              :action="
                () => {
                  setStatusToShowCombo(false);
                  currentAppointment = appointment;
                  realizeAppointment = true;
                  register = true;
                }
              "
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailAppointment = true;
                  realizeAppointment = true;
                }
              "
            />
          </v-col>
          <v-col
            cols="12"
            v-if="nextAppointments.length > 0 || waitingAppointments.length > 0"
          >
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">
              Próximas
            </p>
          </v-col>
          <v-col
            v-for="appointment in nextAppointments"
            :key="appointment.id"
            cols="12"
          >
            <AppointmentCard
              v-if="isScheduled(appointment)"
              color="green"
              :appointment="appointment"
              btn-title="Comparecimento"
              scheduled-type
              :isTelemedicine="appointment.integrationCode ? true : false"
              :action="
                () => {
                  setStatusToShowCombo(false);
                  currentAppointment = appointment;
                  realizeAppointment = true;
                  register = true;
                }
              "
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailAppointment = true;
                  realizeAppointment = true;
                }
              "
            />
            <AppointmentCard
              v-else-if="isWaiting(appointment)"
              color="cyan"
              :appointment="appointment"
              :isTelemedicine="appointment.integrationCode ? true : false"
              :action="
                () => {
                  setStatusToShowCombo(false);
                  currentAppointment = appointment;
                  realizeAppointment = false;
                  register = true;
                }
              "
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailAppointment = true;
                  realizeAppointment = true;
                }
              "
            />
          </v-col>
        </v-row>
        <v-row
          v-if="activeFilter && fullfilledAppointments.length > 0"
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">
              Realizadas
            </p>
          </v-col>
          <v-col
            v-for="appointment in fullfilledAppointments"
            :key="appointment.id"
            cols="12"
          >
            <AppointmentCard
              :appointment="appointment"
              done-type
              :isTelemedicine="appointment.integrationCode ? true : false"
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailAppointment = true;
                  realizeAppointment = true;
                }
              "
              :action="
                () => {
                  setStatusToShowCombo(false);
                  currentAppointment = appointment;
                  realizeAppointment = true;
                  register = true;
                }
              "
            />
          </v-col>
        </v-row>
        <v-row
          v-if="activeFilter && unrealizedAppointments.length > 0"
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">
              Não Realizadas
            </p>
          </v-col>
          <v-col
            v-for="appointment in unrealizedAppointments"
            :key="appointment.id"
            cols="12"
          >
            <AppointmentCard
              :appointment="appointment"
              :isTelemedicine="appointment.integrationCode ? true : false"
              unrealized-type
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailAppointment = true;
                  realizeAppointment = false;
                }
              "
            />
          </v-col>
        </v-row>
      </ProgressiveLoader>
    </v-layout>

    <AppointmentRegister
      :success="
        () => {
          setStatusToShowCombo(false);
          registerSuccess = true;
        }
      "
      :close="
        () => {
          register = false;
          detailAppointment = false;
          realizeAppointment = false;
          currentAppointment = null;
          onCloseFromPushNotification();
          setStatusToShowCombo(true);
        }
      "
      v-if="register"
      :appointment="currentAppointment"
      :clear-appointment="() => (currentAppointment = null)"
      :realize-type="realizeAppointment"
      :detail-only="detailAppointment"
      :userId="user.userId"
      :userCpf="user.cpf"
      :dependentId="user.dependentId"
      :beneficiaries="beneficiaries"
      v-on:updateAppointmentTotalizers="updateAppointmentTotalizers"
      v-on:verifyIsRealized="verifyIsRealized"
    />

    <v-container v-if="registerSuccess">
      <v-card class="success-card" flat>
        <v-container class="pa-7">
          <img
            src="/img/appointments/circle-stethoscope.svg"
            class="success-img"
          />
          <p style="font-size: 36px" class="mt-16 pt-12 main-title">
            Consulta Registrada
          </p>

          <p v-if="!isRealized" style="font-size: 24px" class="pt-4 main-text">
            Não esqueça de informar se foi à consulta
          </p>
          <generic-btn
            style="width: 85%; bottom: 100px; position: absolute"
            :btn-props="{}"
            :on-click="
              () => {
                refreshRegister();
                registerSuccess = false;
                setStatusToShowCombo(true);
              }
            "
          >
            Ok
          </generic-btn>
        </v-container>
      </v-card>
    </v-container>

    <v-card
      v-if="!register && !registerSuccess"
      color="#706CE0"
      class="plus-icon"
      elevation="10"
      @click="
        () => {
          register = true;
          currentAppointment = null;
          realizeAppointment = false;
          setStatusToShowCombo(false);
        }
      "
    >
      <v-layout justify-center align-center>
        <img src="/img/icons/common/plus-icon.svg" class="mt-4" />
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import backArrow from "../../components/covid/back-arrow";
import Totalizer from "@/components/generic-totalizer";
import AppointmentCard from "../../components/appointment/AppointmentCard";
import AppointmentRegister from "../../components/appointment/AppointmentRegister";
import MedicalAppointmentEventService from "../../services/healthcare/MedicalAppointmentEvent";
import { mapMutations } from "vuex";
import moment from "moment";
import { appointmentsStatus, appointmentTypes } from "../../common/constants";
import ProgressiveLoader from "../../components/progressive-loader/index";

export default {
  name: "Appointment",
  components: {
    backArrow,
    Totalizer,
    AppointmentCard,
    AppointmentRegister,
    ProgressiveLoader,
  },
  data() {
    return {
      busy: true,
      totals: [],
      activeFilter: false,
      lateAppointments: [],
      fullfilledAppointments: [],
      unrealizedAppointments: [],
      nextAppointments: [],
      waitingAppointments: [],
      moment,
      register: false,
      registerSuccess: false,
      currentAppointment: null,
      realizeAppointment: false,
      detailAppointment: false,
      isRealized: false,
      orderLoadingTypes: 1,
      orderLoadingTypesRealized: 1,
      pageLoad: -1,
      pageLoadRealizeds: -1,
      selectedLoadingType: "",
      selectedLoadingTypeRealized: "",
      user: {
        name: null,
        cpf: null,
        userId: null,
        dependentId: null
      },
      beneficiaries: [],
      showComboBeneficiaries: true,
    };
  },
  watch: {
    register(newValue) {
      if (!newValue) {
        this.refreshRegister();
      }
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    async refreshRegister() {
      this.nextAppointments = [];
      this.lateAppointments = [];
      this.fullfilledAppointments = [];
      this.unrealizedAppointments = [];
      this.waitingAppointments = [];
      this.orderLoadingTypes = 1;
      this.orderLoadingTypesRealized = 1;
      this.pageLoad = -1;
      this.pageLoadRealizeds = -1;
      this.selectedLoadingType = "";
      this.selectedLoadingTypeRealized = "";
      await this.getAppointments();
      this.updateAppointmentTotalizers();
    },
    isWaiting(appointment) {
      return appointment.status === appointmentsStatus.waiting;
    },
    isScheduled(appointment) {
      return appointment.status === appointmentsStatus.scheduled;
    },
    async onClickTab() {
      this.activeFilter = !this.activeFilter;
      this.clearData();
      await this.getAppointments();
    },
    updateAppointmentTotalizers() {
      this.getTotals();
    },
    verifyIsRealized() {
      this.isRealized = true;
    },
    getAppointmentTypeText(type) {
      if (type === appointmentTypes.self) return "Iniciativa própria";

      if (type === appointmentTypes.suggestion) return "Protocolo Eu Protegido";

      if (type === appointmentTypes.refer) return "Indicação médica";
    },
    async getAppointments() {
      this.loading(true);
      this.busy = true;

      if (!this.activeFilter) {
        this.pageLoad++;
        if (this.orderLoadingTypes == 1) {
          this.selectedLoadingType = "ATRASADO_AGUARDANDO";
        } else if (this.orderLoadingTypes == 2) {
          this.selectedLoadingType = "ATRASADO_AGENDADO";
        } else if (this.orderLoadingTypes == 3) {
          this.selectedLoadingType = "PROXIMO_AGUARDANDO";
        } else {
          this.selectedLoadingType = "PROXIMO_AGENDADO";
        }

        let params = {
          eventStatus: this.selectedLoadingType,
          page: this.pageLoad,
          size: 4,
          sortOrder: "ASC",
        };

        if (this.orderLoadingTypes > 4) {
          this.loading(false);
          this.busy = false;
          return;
        }

        if (this.user.dependentId) {
          this.user.userId = null;
        }

        await this._appointmentEventService
          .findAllByUserAndFilter(params, this.user.userId, this.user.dependentId)
          .then(async (response) => {
            if (response.data && response.data.content.length === 0) {
              this.pageLoad = -1;
              this.orderLoadingTypes++;
              await this.getAppointments();
            } else {
              if (this.selectedLoadingType === "ATRASADO_AGUARDANDO") {
                if (this.lateAppointments && this.lateAppointments.length > 0) {
                  this.lateAppointments = this.lateAppointments.concat(
                    response.data.content
                  );
                } else {
                  this.lateAppointments = response.data.content;
                }
              } else if (this.selectedLoadingType === "ATRASADO_AGENDADO") {
                if (this.lateAppointments && this.lateAppointments.length > 0) {
                  this.lateAppointments = this.lateAppointments.concat(
                    response.data.content
                  );
                } else {
                  this.lateAppointments = response.data.content;
                }
              } else if (this.selectedLoadingType === "PROXIMO_AGUARDANDO") {
                if (this.nextAppointments && this.nextAppointments.length > 0) {
                  this.nextAppointments = this.nextAppointments.concat(
                    response.data.content
                  );
                } else {
                  this.nextAppointments = response.data.content;
                }
              } else {
                if (this.nextAppointments && this.nextAppointments.length > 0) {
                  this.nextAppointments = this.nextAppointments.concat(
                    response.data.content
                  );
                } else {
                  this.nextAppointments = response.data.content;
                }
              }

              if(response.data && response.data.content.length === 1) {
                await this.getAppointments();
              }
            }
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao carregar as consultas atrasadas e/ou próximas.",
              type: "error",
            });
          })
          .finally(() => {
            this.loading(false);
            this.busy = false;
          });
      } else {
        this.pageLoadRealizeds++;
        if (this.orderLoadingTypesRealized === 1) {
          this.selectedLoadingTypeRealized = "REALIZADO";
        } else {
          this.selectedLoadingTypeRealized = "NAO_REALIZADO";
        }

        let params = {
          eventStatus: this.selectedLoadingTypeRealized,
          page: this.pageLoadRealizeds,
          size: 4,
          sortOrder: "DESC",
        };

        if (this.orderLoadingTypesRealized > 2) {
          this.loading(false);
          this.busy = false;
          return;
        }

        if (this.user.dependentId) {
          this.user.userId = null;
        }

        await this._appointmentEventService
          .findAllByUserAndFilter(params, this.user.userId, this.user.dependentId)
          .then(async (response) => {
            if (response.data && response.data.content.length == 0) {
              this.pageLoadRealizeds = -1;
              this.orderLoadingTypesRealized++;
              await this.getAppointments();
            } else {
              if (this.selectedLoadingTypeRealized === "REALIZADO") {
                if (
                  this.fullfilledAppointments &&
                  this.fullfilledAppointments.length > 0
                ) {
                  this.fullfilledAppointments =
                    this.fullfilledAppointments.concat(response.data.content);
                } else {
                  this.fullfilledAppointments = response.data.content;
                }
              } else {
                if (
                  this.unrealizedAppointments &&
                  this.unrealizedAppointments.length > 0
                ) {
                  this.unrealizedAppointments =
                    this.unrealizedAppointments.concat(response.data.content);
                } else {
                  this.unrealizedAppointments = response.data.content;
                }
              }

              if(response.data && response.data.content.length === 1) {
                await this.getAppointments();
              }
            }
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao carregar as consultas realizadas.",
              type: "error",
            });
          })
          .finally(() => {
            this.loading(false);
            this.busy = false;
          });
      }
    },
    getTotals() {
      this.loading(true);
      if (this.user.dependentId) {
        this.user.userId = null;
      }
      this._appointmentEventService
        .getTotalizers(this.user.userId, this.user.dependentId)
        .then(({ data }) => {
          this.totals = [
            {
              label: "Sugestões",
              value: data.totalSuggestionLastYear,
            },
            {
              label: "Próximas",
              value: data.totalSuggestionAndScheduledLastYear,
            },
            {
              label: "Histórico",
              value: data.totalRealizeds,
            },
          ];
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar totalizadores!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    async detailMedicalAppointmentByPushNotification() {
      this.loading(true);
      if (this.$route.params && this.$route.params.id) {
        const id = this.$route.params.id;
        await this._appointmentEventService
          .findById(id)
          .then((response) => {
            if (response.data) {
              this.register = true;
              this.realizeAppointment = true;
              this.currentAppointment = response.data;
            }
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao carregar a consulta notificada.",
              type: "error",
            });
          })
          .finally(() => {
            this.loading(false);
          });
      }
    },
    onCloseFromPushNotification() {
      if (this.$route.params && this.$route.params.id) {
        this.clearData();
      }
    },
    clearData() {
      this.lateAppointments = [];
      this.fullfilledAppointments = [];
      this.unrealizedAppointments = [];
      this.nextAppointments = [];
      this.waitingAppointments = [];
      this.register = false;
      this.registerSuccess = false;
      this.currentAppointment = null;
      this.realizeAppointment = false;
      this.detailAppointment = false;
      this.isRealized = false;
      this.orderLoadingTypes = 1;
      this.orderLoadingTypesRealized = 1;
      this.pageLoad = -1;
      this.pageLoadRealizeds = -1;
      this.selectedLoadingType = "";
      this.selectedLoadingTypeRealized = "";
    },
    setStatusToShowCombo(status) {
      this.showComboBeneficiaries = status;
    },
    onClickComboBeneficiary() {
      this.refreshRegister();
    },
    initUserId() {
      if (this.$auth.user() && this.$auth.user().beneficiaries) {
        let user = this.$auth.user().beneficiaries[0];
        this.user = {cpf: user.personCPF, userId: user.userId, name: user.personName}
      } else if (this.$auth.user() && this.$auth.user().dependents) {
        let user = this.$auth.user().dependents[0];
        this.user = {cpf: user.personCPF, name: user.personName, userId: user.userId, dependentId: user.dependentId}
      } else {
        this.user.cpf = this.$auth.user() ? this.$auth.user().cpf : null;
      }
      this.beneficiaries = this.$auth.getBeneficiariesAndDependents();
    },
  },
  async mounted() {
    this.initUserId();
    await this.detailMedicalAppointmentByPushNotification();
    this.getTotals();
    await this.getAppointments();
  },
  beforeCreate() {
    this._appointmentEventService = new MedicalAppointmentEventService();
  },
};
</script>

<style >
/*.v-icon.v-icon  {*/
/*  color: var(--v-tertiary) !important;*/
/*  font-size: 15px !important;*/
/*  padding-left: 20px !important;*/
/*}*/

.bg-effect {
  background: rgba(255, 255, 255, 0.5);
  mix-blend-mode: normal;
  padding: 10px;
  height: 100% !important;
}

.list-card {
  border-radius: 20px !important;
}

.option-list-card {
  width: 100% !important;
  height: 66px;
}

.plus-icon {
  border-radius: 50% !important;
  height: 60px;
  width: 60px;
  position: fixed !important;
  right: 30px;
  bottom: 30px;
}

.success-card {
  position: absolute;
  bottom: 0;
  left: 7.5%;
  height: 80vh;
  width: 85vw;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  z-index: 10;
}

@media (max-width: 389px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 13px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 4px !important;
  }
}

@media (min-width: 390px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 16px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 2px !important;
  }
}

@media (max-width: 379px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 24%;
    top: -100px;
  }
}

@media (min-width: 380px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 27.5%;
    top: -100px;
  }
}
</style>
