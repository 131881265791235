import axios from 'axios';
import $auth from '../../common/auth';

const {
    VUE_APP_API_URL
// eslint-disable-next-line no-undef
} = process.env;

const VUE_APP_HEALTHCARE_URL = VUE_APP_API_URL + '/healthcare/medical_speciality/';

const getAllSpecialities = () => {
    const url = `${VUE_APP_HEALTHCARE_URL}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

export {
    getAllSpecialities
};
